import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import List from '../components/List'
import MissionSeekGiveHigher from '../components/MissionSeekGiveHigher'
import PartnersList from '../components/PartnersList'
import SponsorsList from '../components/SponsorList'
import Block from '../components/Block'
import TestimonialList from '../components/TestimonialList'
// import conferenceAd from '../images/NationalConference-large.png'

const IndexPage = () => {
  const postDisplaySize = 4
  const MISSIONS_INTERLINK_ID = 902
  const eventsContext = useContext(GlobalStateContext)
  const [events, setEvents] = useState(eventsContext.events)

  const miEvents = events
    .filter(item => item.charity.id === MISSIONS_INTERLINK_ID)
    .slice(0, postDisplaySize)

  useEffect(() => {
    setEvents(eventsContext.events)
  }, [eventsContext.events])

  return (
    <Layout>
      <SEO title="Home" />

      {/* <Link to="/events/missions-interlink-national-conference-2024/348">
        <img
          src={conferenceAd}
          alt="Conference Ad"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </Link> */}

      <div className="call-to-action">
        <h1 style={{ color: 'white' }} className="container call-to-action-title-1 mb-2">
          <Block contentfulID="7h5koj1xFCUbpll67JmCrX" hLevel="0" />
        </h1>
        <p className="call-to-action-title-2 mb-2">
          <Block contentfulID="2qp4eZUCOqAlotB7HtKHmQ" hLevel="0" />
        </p>
        <Link to="/membership/benefits" className="mb-5">
          <div className="btn btn-call-to-action btn-primary">Learn more</div>
        </Link>
      </div>

      <section className="container-cards-call-to-action">
        <div className="cards-call-to-action">
          <article>
            <Block contentfulID="73j8TRJXg7kRQERo6X1tNa" hLevel="h3" />
          </article>
          <article>
            <Block contentfulID="un3Fevtzd4G5eZx5yZt2d" hLevel="h3" />
          </article>
          <article>
            <Block contentfulID="1YID5IkXwumScv9rGGyeJb" hLevel="h3" />
          </article>
          <article>
            <Block contentfulID="QKPQkwiWXIaNfkUB9eiYj" hLevel="h3" />
          </article>
        </div>
      </section>
      <section className="section">
        <h2>Upcoming MI Events</h2>
        <hr className="mb-2" />
        <List data={miEvents} itemType="events" className="items-center items-container" />
        <Link to="/events" className="btn btn-primary mt-2">
          See more!
        </Link>
      </section>
      <MissionSeekGiveHigher />
      <section className="section">
        <h2>Testimonials</h2>
        <hr className="mb-2" />
        <TestimonialList type={null} count={4} />
        <Link to="/testimonials" className="btn btn-primary mt-2">
          See more!
        </Link>
      </section>
      <section className="section partners-section">
        <h2>Partners</h2>
        <hr className="mb-2" />
        <PartnersList />
      </section>
      <section className="section sponsors-section">
        <h2>Sponsors</h2>
        <hr className="mb-2" />
        <SponsorsList />
      </section>
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
