import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import List from './List'

const PartnersList = () => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulPartners(sort: { fields: order, order: ASC }) {
        edges {
          node {
            id
            name
            slug
            callToActionLink
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const IMAGE_HEIGHT = null
  const data = queryData.allContentfulPartners.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.name,
    body: null,
    image: obj.node.logo,
    imageHeight: IMAGE_HEIGHT,
    slug: `/about/ourpartners/${obj.node.slug}`,
    category: obj.node.category,
    type: null
  }))

  return <List data={data} itemType="subMenu" className="items-center items-container" />
}

export default PartnersList
