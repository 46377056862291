import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import richTextOptions from '../shared/richTextOptions'

const Block = ({ contentfulID, hLevel }) => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulBlockTitleAndBody(sort: { fields: description, order: ASC }) {
        edges {
          node {
            contentful_id
            title
            description
            body {
              json
            }
          }
        }
      }
    }
  `)

  const data = queryData.allContentfulBlockTitleAndBody.edges.find(
    item => item.node.contentful_id === contentfulID
  )

  if (!data) console.log(`Block: ${contentfulID} not found.`, data)
  const { title, body } = data ? data.node : { title: null, body: null }

  return (
    <>
      {hLevel === '0' && title}
      {hLevel === 'h1' && <h1>{title}</h1>}
      {hLevel === 'h2' && <h2>{title}</h2>}
      {hLevel === 'h3' && <h3>{title}</h3>}
      {hLevel === 'h4' && <h4>{title}</h4>}
      <span className="text-content">
        {body && documentToReactComponents(body.json, richTextOptions)}
      </span>
    </>
  )
}

Block.propTypes = {
  contentfulID: PropTypes.string.isRequired,
  hLevel: PropTypes.string.isRequired
}

export default Block
