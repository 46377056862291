import React from 'react'
import giveHigher from '../images/givehigher-blue.svg'
import missionSeek from '../images/mission-seek-logo.png'

const MissionSeekGiveHigher = () => {
  return (
    <div className="row missionseek_container d-flex justify-content-center">
      <div className="col-md-5 text-center" style={{ paddingBottom: '20px' }}>
        <a href="https://www.missionseek.com.au/" target="_blank" rel="noopener noreferrer">
          <img style={{ margin: '2px 0 4px 0' }} alt="MissionSeek" src={missionSeek} />
        </a>
        <figure className="video">
          <iframe
            title="MissionSeek"
            src="https://www.youtube.com/embed/fbPyuzs7jbY"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </figure>
        <p style={{ paddingTop: '10px' }}>
          Connecting Australian Christians to mission opportunities.
        </p>
        <a
          className="btn btn-primary"
          href="https://www.missionseek.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Serve on Mission
        </a>
      </div>
      <div className="col-md-5 text-center" style={{ paddingBottom: '20px' }}>
        <a href="https://givehigher.com.au/" target="_blank" rel="noopener noreferrer">
          <img
            style={{ margin: '12px 0 14px 0' }}
            height="40px"
            alt="GiveHigher"
            src={giveHigher}
          />
        </a>
        <figure className="video">
          <iframe
            title="GiveHigher"
            src="https://www.youtube.com/embed/AWYnXpECEZE"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </figure>
        <p style={{ paddingTop: '10px' }}>Invest in mission projects and Christian workers.</p>
        <a
          className="btn btn-primary"
          href="https://givehigher.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Give to Mission
        </a>
      </div>
    </div>
  )
}

export default MissionSeekGiveHigher
