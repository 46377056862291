import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import List from './List'

const SponsorsList = () => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulPartners(sort: { fields: name, order: ASC }) {
        edges {
          node {
            id
            name
            slug
            callToActionLink
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const SPONSORS = ['EA Insurance Services']

  const IMAGE_HEIGHT = null
  const data = queryData.allContentfulPartners.edges
    .filter(item => SPONSORS.includes(item.node.name))
    .map(obj => ({
      id: obj.node.id,
      title: obj.node.name,
      body: null,
      image: obj.node.logo,
      imageHeight: IMAGE_HEIGHT,
      slug: `/about/ourpartners/${obj.node.slug}`,
      category: obj.node.category,
      type: null
    }))

  return <List data={data} itemType="subMenu" className="items-center items-container" />
}

export default SponsorsList
